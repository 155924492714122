<template>
  <div class="general-container grey-background">
    <the-navbar />
    <b-loading
      v-if="isLoading"
      :is-full-page="true"
      :can-cancel="false"
      class="fill-screen"
      :active="true"
    />
    <project v-else />
  </div>
</template>

<script>
import Project from '@/components/interactions/Project.vue'
import TheNavbar from '@/components/TheNavbar.vue'

export default {
  name: 'ProjectInteractions',
  components: {
    Project,
    TheNavbar
  },

  data() {
    return {
      isLoading: true
    }
  },

  async created() {
    // Fetch the Project data
    const params = {
      projectIdentifier: this.$route.params.projectIdentifier
    }

    const success = await this.$store.dispatch('interactionsInteractions/getTaskListData', params)

    if (success) {
      this.isLoading = false
    }
  }
}
</script>
